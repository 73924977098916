#calculator_banner {
    width: 100%;
    background-image: url('./img/hero_banner_background.jpg');
    background-size: cover;
    background-position: center;
    color: $brand-primary;
    text-shadow: 2px 2px 8px #fff;
    .lead {
        font-weight: bold;
    }
    .container {
        padding: 80px;
    }
    h1 {
        font-weight: bold;
        text-shadow: 2px 2px 8px $brand-primary;
        color: #fff;
    }
}

#debt_calculator_section {
    background-color: #fff;
    padding: 40px 0 60px;
    .well {
        border-radius: 0px;
        background-color: #fff;
    }
    .nav-tabs-justified, .nav-tabs.nav-justified {
        border: solid 1px #ddd;
    }
    .nav-tabs li.active a {
        color: #fff;
        border-radius: 0px;
    }
}
