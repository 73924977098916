section#user_dashboard {
    background-color: #fff;
    padding: 40px 0;
}

#user_dashboard_accordion .panel-heading {
    background-color: $body-bg;
    color: #fff;
}

#user_dashboard_accordion {
    .panel {
        margin-bottom: 20px;
    }
}

.tooltip-inner {
    background-color: #1C2B4B !important;
    color: #fff;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #1C2B4B;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #1C2B4B;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #1C2B4B;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #1C2B4B;
}
