#welcome_banner {
    width: 100%;
    background-image: url('./img/hero_banner_background.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 200px 0px;
    h1 {
        font-weight: bold;
        text-shadow: 2px 2px 8px $brand-primary;
    }
}

#features {
    .fa {
        color: #ccc;
    }

    .well {
        background:none;
        border: solid 1px $brand-primary;
        box-shadow: none;
        padding: 40px;

    }
}

#about-us {
    width: 100%;
    background-image: url('./img/about_us_background.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
}

#contact {
    color: #fff;
}

#contact-page {
    background-color: #fff;
    padding: 40px 0;
}
