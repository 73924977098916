
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// 3rd parties
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome.scss";
@import "node_modules/jquery-confirm/css/jquery-confirm";

// Modules
@import "navbars";
@import "welcome";
@import "login";
@import "debt_calculator";
@import "user_dashboard";

html, body, #app {
    height: 100%;
}

#page_loading {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 40px;
    text-align:center;
    background-color: $brand-primary;
    z-index: 2000;
}

section.article {
    background-color: #fff;
    padding: 40px 0 60px;
}

.dotted {
    padding: 2.25em 1.6875em;
    background-image: -webkit-repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    background-image: -moz-repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    background-image: -ms-repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    background-image: repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    -webkit-background-size: 3px 3px;
    -moz-background-size: 3px 3px;
    background-size: 3px 3px;
}

.panel-default > .panel-heading {
    background-color: #ccc;
}

.panel-default, .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-color: #ccc;
}

.period-group .form-control{
    width:50%;
  }
  
.load_privacy_policy, .load_terms_of_use{
    color: #ccc;
}
.load_privacy_policy:hover, .load_terms_of_use:hover{
    color: white;
}
.no-hor-padding{
    padding-left: 0;
    padding-right:0;
}
input[type="range"]:focus {
    border-color: none;
    box-shadow: none;
}
#debt_amount::-webkit-inner-spin-button, 
#debt_amount::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
    .print, .print *
    {
        display: block !important;
    }
    .panel {page-break-before: always;}

    a[href]:after {
        content: none !important;
    }
    abbr[title]:after{
        content: none !important;
    }
}
.jconfirm .jconfirm-box div.jconfirm-closeIcon{
    right: 20px;
}
.jconfirm .jconfirm-box div.jconfirm-content-pane::-webkit-scrollbar {
    width: 6px;
}
.ccr_errors{
    border-collapse: collapse;
    
    th, td {
        border: 1px solid black;
    }
}
.landing 
{
    .panel-default {
        opacity: 0.9;
        form
        {
            h2 {
                color: #1C2B59;
            }
            a:hover{
                color: black;
            }
            color: black;
        }
        .panel-body{
            .form-control{
                background-color: #deeff5;
            }
        }
        .panel-footer{
            padding: 20px 15px;
            background-color:#1C2B4B;
            color:white;
            a{
                color: white;
            }
            a:hover{
                color: #ccc;;
            }
        }
    }
    .text-blue{
        color: #1C2B4B;
    }
    td { text-align: left; }
    td:first-child { text-align: center;}
    .dotted {
        color: white;
    }
    p a {
        color: #ccc;
    }
    section.breaker{
        background-color:  #ccc;
        padding: 40px 0;
    }
}
.navbar .pull-right{
    width: 80%;
}