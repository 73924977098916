section#login {
    width: 100%;
    background-image: url('./img/hero_banner_background.jpg');
    padding: 80px 0;
    .panel {
        background-color: rgba(255, 255, 255, 0.8);
    }
    .panel-heading {
        background-color: $brand-primary;
        color: #fff;
    }
    .panel-body {
        padding: 40px inherit;
    }
}
